import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { FunctionComponent } from "react";

import CollectionIcon from "@/components/Icons/CollectionIcon";
import LogoutIcon from "@/components/Icons/LogoutIcon";
import RequestIcon from "@/components/Icons/RequestIcon";
import SettingIcon from "@/components/Icons/SettingIcon";
import { poppins } from "@/utils/font";

const AdminSidebar: FunctionComponent = () => {
  const { logout } = useAuthState();

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const pathName = usePathname();

  const navLinks = [
    {
      icon: (active = false) => (
        <RequestIcon
          className={clsMerge(
            "mb-[1px] h-[14px] w-[14px] fill-[#AFB0B4] ",
            active ? "stroke-white" : "hover:stroke-white group-hover:stroke-white",
          )}
        />
      ),
      label: "Requests",
      href: "/requests",
      query: {
        tab: "pending",
      },
    },
    {
      icon: (active = false) => (
        <CollectionIcon
          className={clsMerge(
            "mb-[1px] h-[12px] w-[12px] fill-[#343434] stroke-[#AFB0B4]",
            active ? "stroke-white" : "group-hover:stroke-white",
          )}
        />
      ),
      label: "Collections",
      query: undefined,
      disabled: true,
      hidden: true,
    },
    {
      icon: (active = false) => (
        <SettingIcon
          className={clsMerge(
            "mb-[1px] h-[14px] w-[14px] fill-[#343434] stroke-[#AFB0B4]",
            active ? "fill-white" : "group-hover:stroke-white",
          )}
        />
      ),
      label: "Settings",
      query: undefined,
      disabled: true,
      hidden: true,
    },
    {
      icon: (active = false) => (
        <LogoutIcon
          className={clsMerge(
            "mb-[1px] h-[14px] w-[14px] fill-[#343434] stroke-[#AFB0B4]",
            active ? "fill-white" : "group-hover:stroke-white",
          )}
        />
      ),
      label: "Logout",
      href: "/#",
      onClick: () => logout("/"),
      query: undefined,
    },
  ];

  return (
    <>
      <nav className="relative z-10 flex flex-wrap items-center justify-between bg-[#343434] py-4 shadow-xl md:absolute md:bottom-0 md:left-0 md:top-0 md:block md:w-64 md:flex-row md:flex-nowrap md:overflow-hidden md:overflow-y-auto">
        <div className="mx-auto flex w-full flex-wrap items-center justify-between px-0 md:min-h-full md:flex-col md:flex-nowrap md:items-stretch">
          <button
            className="cursor-pointer rounded border border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none text-black opacity-50 md:hidden"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars" />
          </button>
          <div
            className={clsMerge(
              "absolute left-0 right-0 top-0 z-40 h-auto flex-1 items-center overflow-y-auto overflow-x-hidden rounded text-white shadow md:relative md:mt-4 md:flex md:flex-col md:items-stretch md:opacity-100 md:shadow-none",
              poppins.className,
              collapseShow,
            )}
          >
            {/* Collapse header */}
            <div className="border-blueGray-200 mb-4 block border-b border-solid pb-4 md:hidden md:min-w-full">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="text-blueGray-600 mr-0 inline-block whitespace-nowrap p-4 px-0 text-left text-sm font-bold uppercase md:block md:pb-2"
                    href="/"
                  >
                    NGS
                  </Link>
                </div>
                <div className="flex w-6/12 justify-end">
                  <button
                    className="cursor-pointer rounded border border-solid border-transparent bg-transparent px-3 py-1 text-xl leading-none text-black opacity-50 md:hidden"
                    type="button"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
              </div>
            </div>

            <ul className="flex list-none flex-col gap-y-4 md:mt-[108px] md:min-w-full md:flex-col">
              {navLinks
                .filter(each => !each.hidden)
                .map((each, index) => {
                  const active = pathName === each.href;
                  const search = new URLSearchParams(each.query);
                  const url = each.href + (each.query ? `?${search.toString()}` : "");
                  return (
                    <li
                      className={clsMerge(
                        "relative items-center",
                        each?.disabled && "pointer-events-none cursor-not-allowed",
                      )}
                      key={`${each.href}.${index}`}
                      onClick={each.disabled ? () => {} : each?.onClick}
                    >
                      <Link
                        className={clsMerge(
                          "group flex items-center justify-start gap-x-2 px-9 py-2 text-sm leading-normal",
                          active
                            ? "font-bold text-white"
                            : "font-medium text-[#AFB0B4] hover:text-white",
                        )}
                        href={url}
                      >
                        {each.icon(active)}
                        {each.label}
                        {active && (
                          <div className="absolute right-0 flex h-full w-2 self-center bg-[#B4171E]" />
                        )}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminSidebar;

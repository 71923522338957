import { useAuthState } from "@artifactlabs/react-auth";
import { useSearchParams, useRouter } from "next/navigation";
import { FC, useEffect } from "react";
import { useRecoilState } from "recoil";

import { useLazyGetUserIsAdmin } from "@/hooks/requests/useGetUserIsAdmin";
import authAtom from "@/recoil/auth";

const withIsAdminRequired = <T,>(WrappedComponent: FC<T>) => {
  const WrappedAuthComponent = (props: T) => {
    const { user, isAuthenticated, isLoading, logout, getAccessToken } = useAuthState();
    const [authState, setAuthState] = useRecoilState(authAtom);
    const [getUserIsAdmin] = useLazyGetUserIsAdmin();
    const query = useSearchParams();
    const router = useRouter();

    useEffect(() => {
      const fetchUserIsAdmin = async () => {
        try {
          if (user) {
            const res = await getUserIsAdmin();
            const { isAdmin } = res?.data?.user as any;
            if (isAdmin) {
              const returnTo =
                authState?.returnTo || `${window.location.pathname}${window.location.search}`;
              setAuthState({ isAdmin: true, isLoading: false });
              router.push(returnTo || "/");
            } else {
              logout("/");
            }
          }
        } catch (error) {
          console.log("error", error);
          setAuthState({ isAdmin: false, isLoading: false });
          logout("/");
        }
      };

      if (!isLoading && isAuthenticated && authState?.isLoading) {
        fetchUserIsAdmin();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isLoading,
      isAuthenticated,
      getUserIsAdmin,
      logout,
      router,
      setAuthState,
      getAccessToken,
      query,
    ]);

    return <WrappedComponent {...(props as any)} />;
  };

  return WrappedAuthComponent;
};

export default withIsAdminRequired;
